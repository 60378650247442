import * as React from 'react';

import { BackgroundTexture, Layout, SEO } from '../components';
import { ContactFormWithMap } from '../components/contact-form-with-map';

function ContactPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <ContactSection />
    </Layout>
  );
}

function ContactSection() {
  return (
    <div className="relative -mt-12">
      <div aria-hidden className="fixed inset-0">
        <BackgroundTexture insetBottom="full" overflowBottom />
      </div>
      <ContactFormWithMap isReversed />
    </div>
  );
}

export { ContactPage as default };
